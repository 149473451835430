/* eslint-disable react/no-array-index-key */
import React, { PureComponent } from 'react'
import { graphql } from 'gatsby'
import { format, parse } from 'date-fns'

import SEO from '../components/seo'
import {
  FuneralInfo,
  ImageAndMessage,
  MessageOnly,
  QuoteAndMessage,
  YoutubeVideo,
} from '../components/Templates'
import Clouds from '../components/Effects/Clouds'

class Obituary extends PureComponent {
  componentDidMount() {
    const clouds = new Clouds()
    clouds.generate()
    clouds.update()
  }

  render() {
    const { data } = this.props
    const { logo, name, people } = data.company
    const arrayObituaries = []
    people.map(person => {
      const { birthday, deathday, id, memorial, personName } = person
      if (person.obituaries.length === 0) {
        const isPrivate = person.private
        const personData = {
          birthday,
          deathday,
          id,
          personName,
          personPhoto: memorial.personPhoto,
          itemType: 'memorial',
        }
        if (!isPrivate) {
          arrayObituaries.push(personData)
        }
      } else {
        person.obituaries.map(obituary => {
          const personData = {
            birthday,
            deathday,
            id,
            personName,
            itemType: 'tribute',
            ...obituary,
          }
          return arrayObituaries.push(personData)
        })
      }
    })

    // debugger
    arrayObituaries.sort((a, b) => {
      const aDate = new Date(a.dateCreated.seconds * 1000)
      const bDate = new Date(b.dateCreated.seconds * 1000)
      return bDate - aDate
    })
    return (
      <>
        <SEO
          title={`Memorial de ${name}`}
          keywords={[`Immortalis`, `Nota de falecimento`, `Obituário de ${name}`]}
        />
        <script
          type="text/javascript"
          src="//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c6326c6a041fceb"
        />
        <div className="relative w-100 h-auto overflow-hidden">
          <div id="viewport">
            <div id="world" />
          </div>
          <header className="tc pt4 pt5-m pt6-l pb3 pb4-m pb5-l flex flex-wrap items-center justify-center overflow-hidden z-max relative">
            <div className="w-100">
              <img
                alt={`${name} Logo`}
                src={logo}
                className="w4 w5-ns center"
                style={{
                  filter: 'brightness(10000%)',
                }}
              />
            </div>
            <div className="addthis_inline_share_toolbox tc mt3" />
          </header>
        </div>
        <div className="business mt3 mw9-ns center">
          <h1 className="tc fw4 f3 mv0 pv3 pv4-ns ph4">
            <small className="db fw1 f7 i dark-gray mb2">Memorial de</small>
            {name}
          </h1>
          <div className="masonry">
            {arrayObituaries.map((item, index) => {
              const {
                birthday,
                deathday,
                dateCreated,
                id,
                image,
                itemType,
                message,
                personName,
                personPhoto,
                sender,
                type,
                venueAddress,
                venueDateTime,
                venueName,
              } = item
              const parsedDate = new Date(dateCreated.seconds * 1000)
              return (
                <div key={index} className="masonry-item relative w-100 pa2 ma0">
                  <div>
                    {type === 'ImageAndMessage' && (
                      <div>
                        <ImageAndMessage
                          alt={`Imagem de ${personName}`}
                          image={image}
                          message={message}
                          personName={personName}
                          sender={sender}
                        />
                        <small className="tr w-100 mid-gray mt2 db">
                          Publicado em&nbsp;
                          {format(parsedDate, 'dd/MM/yyyy', { awareOfUnicodeTokens: true })}
                        </small>
                      </div>
                    )}
                  </div>
                </div>
              )
            })}
          </div>
          <div className="ph3">
            <div className="ba br3 b--mid-gray gray w-50-ns center pa3 mv5">
              <h2 className="f4 f3-ns ma0 fw5 mb2 lh-title">
                Crie também um memorial para sua igreja
              </h2>
              <p className="ma0 pa0 lh-copy f5 f4-ns">
                Preste uma homenagem aos membros que fizeram parte da história de sua igreja.{' '}
                <a href="/business" className="black">
                  Saiba mais
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default Obituary

export const pageQuery = graphql`
  query companyQuery($id: String!) {
    company(id: { eq: $id }) {
      companyAddress {
        address
      }
      id
      logo
      name
      people {
        birthday
        deathday
        id
        memorial {
          id
          personPhoto
        }
        obituaries {
          dateCreated {
            seconds
            nanoseconds
          }
          id
          image
          message
          quote
          quoteBackgroundColor
          quoteBackgroundImage
          quoteFontColor
          sender
          type
          venueAddress
          venueDateTime
          venueName
        }
        personName
        private
      }
      website
    }
  }
`
